import React, { useState, useEffect, useCallback } from "react";
import { getUser } from "../services/utils";
import { useUser } from "../context/UserProvider";
import Loader from "../components/Shared/Loader";
import { getToken } from "../services/cookies";
import api from "../services/api";

import { useLocation, useHistory } from "react-router-dom";

function withUser(WrappedComponent) {
  function Enhancer(props) {
    const [isLoading, setIsLoading] = useState(true);

    // const snack = useSnack();
    const location = useLocation();
    const history = useHistory();
    const userState = useUser();

    const tokenCookies = useCallback(() => {
      let tokenLocal;
      tokenLocal = getToken();
      return tokenLocal;
    }, []);

    // useEffect(() => {
    //   const getNewToken = async () => {
    //     // update user
    //     console.log("TOKEN EXPIRED");
    //     // userState.functions.logOut();

    //     const res = await api.update_token({ jwt: tokenCookies() });
    //     if (res) {
    //       setToken(res.jwt);
    //       userState.functions.logOut();
    //     } else {
    //       console.log("Failed to get a user");
    //     }
    //     //   console.log(res);
    //   };
    //   if (tokenCookies() && isTokenExpired(tokenCookies())) getNewToken();
    // }, []);

    const userCookies = useCallback(() => {
      let userLocal = getUser(tokenCookies());
      return userLocal;
    }, [tokenCookies]);

    useEffect(() => {
      const setUser = async () => {
        const userId =
          userCookies() && userCookies().userInfo
            ? userCookies().userInfo.id
            : userCookies().id;

        const res = await api.get("users", userId);
        // const res = await apiCall.users.getMe();
        if (res) {
          userState.functions.setUser(res);
          userState.functions.setToken(tokenCookies());
          console.log("User had been set");
        } else {
          console.log("Failed to get a user");
        }
      };
      const cleanUser = () => {
        userState.functions.setUser(null);
        userState.functions.setToken(null);
      };

      if (
        (!userState.user && userCookies()) ||
        (tokenCookies() && !userState.token)
      ) {
        setUser();
      }
      if (
        (userState.token && !tokenCookies()) ||
        (userState.user && !userCookies())
      ) {
        cleanUser();
      }
      if (!userCookies() && location.pathname.includes("/dashboard")) {
        console.log("Suppose to push to /");
        history.push("/");
      } else {
        setIsLoading(false);
      }
    }, [
      userState.user,
      userState.token,
      location.pathname,
      userState.functions,
      userCookies,
      tokenCookies,
      history,
    ]);

    if (userState.user || !tokenCookies()) {
      return (
        <div>
          <WrappedComponent {...props} user={userState.user || null} />
          {/* <Snacks
            success={success}
            openSnackBar={openSnackBar}
            message={messageSnack}
            setOpenSnackBar={setOpenSnackBar}
          /> */}
        </div>
      );
    } else if (isLoading) {
      return (
        <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
          <Loader />
        </div>
      );
    } else if (!userState.user && userCookies()) {
      return (
        <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
          <Loader />
        </div>
      );
    }
  }
  return Enhancer;
}

export default withUser;
