import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import api from "../services/api";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { useUser } from "../context/UserProvider";

function MyPage(props) {
  const [avatarFile, setAvatarFile] = useState(null);
  const [description, setDescription] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [update, setUpdate] = useState(false);

  const { user } = useUser();

  useEffect(() => {
    const fetchCity = async () => {
      console.log("Fetching city");
      const res = await api.get("cities", user.city);
      if (res) {
        console.log(res);
        setCurrentData({
          img: res.logo?.url || null,
          description: res.description || null,
        });
      } else {
        console.log("Couldn't get city");
      }
    };

    const fetchCompany = async () => {
      console.log("Fetching company");
      const res = await api.get("companies", user.company);
      if (res) {
        console.log(res);
        setCurrentData({
          img: res.logo?.url || null,
          description: res.description || null,
        });
      } else {
        console.log("Couldn't get conmpany");
      }
    };
    console.log(user);

    user.is_city_admin && fetchCity();
    user.is_company_admin && fetchCompany();
  }, [update, user]);

  const imgStyle = (img) => {
    return {
      backgroundImage: `url(${img})`,
    };
  };

  if (avatarFile) {
    var reader = new FileReader();

    reader.onload = function (e) {
      setImgPreview(e.target.result);
    };

    reader.readAsDataURL(avatarFile[0]);
  }

  const handleSaveNewData = async () => {
    console.log("New data");

    if (description) {
      let ref = user.is_city_admin
        ? "cities"
        : user.is_company_admin
        ? "companies"
        : null;

      let refId = user.is_city_admin
        ? user.city
        : user.is_company_admin
        ? user.company
        : null;

      const res = await api.update(ref, refId, { description });
      if (res) {
        console.log(res);
      } else {
        console.log("couldn't");
      }
    }

    if (avatarFile) {
      // setIsLoading(true);
      let ref = user.is_city_admin
        ? "cities"
        : user.is_company_admin
        ? "companies"
        : null;

      let refId = user.is_city_admin
        ? user.city
        : user.is_company_admin
        ? user.company
        : null;

      let formData = new FormData();
      formData.append("files", avatarFile[0]);
      formData.append("refId", refId);
      formData.append("field", "logo");
      formData.append("ref", ref);

      let res = await api.uploadFile(formData);

      if (res) {
        // setIsLoading(false);
        // functions.logOut();
        // snack.functions.setSnack({
        //   message: content.SUCCESS.INFO_CHANGED,
        //   success: true,
        // });
        console.log("DONE file upload");
      } else {
        // setShowAlertAva({
        //   show: true,
        //   severity: "warning",
        //   text: content.UPLOAD_AVATAR_ERROR,
        // });
        console.log("ERROR in file upload");

        // setIsLoading(false);
      }
    }

    setDescription(null);
    setAvatarFile(null);
    setImgPreview(null);
    setUpdate(!update);
  };

  return (
    <div className={"page-form-wrap"}>
      <div
        className={"photo-input-wrap"}
        style={
          imgPreview
            ? imgStyle(imgPreview)
            : currentData?.img
            ? imgStyle(currentData.img)
            : null
        }
      >
        <div className={"photo-input-wrap-overlay"}>
          <input
            type="file"
            id="file"
            name="files"
            onChange={(e) => setAvatarFile(e.target.files)}
            className="inputfile"
          />
          <label for="file">
            <AddPhotoAlternateIcon />
          </label>
        </div>
        {/* {showAlertAva.show ? (
          <div className={styles.alert_wrap}>
            <Alert severity={showAlertAva.severity}>{showAlertAva.text}</Alert>
          </div>
        ) : null} */}
      </div>
      {/* <TextField
        label="Name"
        value={pageData.name}
        onChange={(e) => setPageData({ ...pageData, name: e.target.value })}
        variant="outlined"
      /> */}
      <TextField
        label="Description"
        rows={10}
        className="text-description"
        multiline
        value={
          description
            ? description
            : currentData?.description
            ? currentData.description
            : ""
        }
        onChange={(e) => setDescription(e.target.value)}
        variant="outlined"
      />{" "}
      <div
        style={{
          display: "flex",
          width: "320px",
          justifyContent: "space-between",
        }}
      >
        <div
          className={
            avatarFile || description ? "button_main" : "button_disabled"
          }
          onClick={handleSaveNewData}
        >
          Enregistrer
        </div>

        <div
          className="button_cancel"
          onClick={() => {
            setDescription(null);
            setAvatarFile(null);
            setImgPreview(null);
          }}
        >
          Réinitialiser
        </div>
      </div>
    </div>
  );
}

export default MyPage;
