import React, { useReducer, useContext, useMemo } from "react";

const Snack = React.createContext();

const initiState = {
  success: null,
  message: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SNACK":
      return Object.assign({}, state, {
        ...state,
        success: action.success,
        message: action.message,
      });
    case "CLEAN_SNACK":
      return Object.assign({}, state, { ...state, message: null });
    // case "UPDATE_AVATAR":
    //   let userUpdate = Object.assign({}, state);
    //   userUpdate.user.avatar = action.avatar;
    //   return userUpdate;
    // case "SET_STRIPE_DATA":
    //   return Object.assign({}, state, {
    //     ...state,
    //     stripe_data: { stripe_id: action.stripe_id, user_id: action.user_id },
    //   });
    // case "CLEAR_STRIPE_DATA":
    //   return Object.assign({}, state, {
    //     ...state,
    //     stripe_data: null,
    //   });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const SnackProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initiState);

  const functions = useMemo(
    () => ({
      setSnack: async (data) =>
        dispatch({
          type: "SET_SNACK",
          message: data.message,
          success: data.success,
        }),
      cleanSnack: async () => dispatch({ type: "CLEAN_SNACK" }),
    }),
    []
  );

  return (
    <Snack.Provider
      value={{ success: state.success, message: state.message, functions }}
    >
      {children}
    </Snack.Provider>
  );
};

export const useSnack = () => useContext(Snack);
