import React from "react";
import "../../styles/dataVisualisation.scss";

export default function InfoCard({ title, value, type, units }) {
  console.log(value);

  const defineTitle = (ind) => {
    switch (ind) {
      case 0:
        return "< 1 sem";
      case 1:
        return "1 sem > ... < 2 sem";
      case 2:
        return "> 2 sem";
      case 3:
        return "Total";

      default:
        break;
    }
  };

  const formatDuration = (d) => {
    const hours = d / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours}h ${rminutes}min`;
  };

  return (
    <div
      style={{
        width: "150px",
        padding: "10px",
        margin: "1rem",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          textAlign: "center",
          height: "100%",
        }}
      >
        <h2 className="data-visual-title">
          <div>{title}</div>
          <p className="data-visual-units">{units}</p>
        </h2>
        <div className="data-visual-wrap">
          {Object.keys(value).map((key, ind) => {
            let data;
            switch (ind) {
              case 0:
                data = value.dataFirstWeek;
                break;
              case 1:
                data = value.dataSecondWeek;
                break;
              case 2:
                data = value.dataAfterSecondWeek;
                break;
              case 3:
                data = value.dataTotal;
                break;
              default:
                break;
            }
            return (
              <div className={`data-visual-box${ind}`}>
                <p className="data-visual-title-period">
                  {defineTitle(ind) || ""}
                </p>
                {type === "duration" ? (
                  <p className="data-visual-data">
                    {" "}
                    <span style={{ fontSize: "1.5rem" }}>
                      {formatDuration(data[type]) || "-"}
                    </span>
                  </p>
                ) : type === "users" ? (
                  <p className="data-visual-data">
                    {data.allAnswers.length || "-"}
                  </p>
                ) : (
                  <p className="data-visual-data">{data[type] || "-"}</p>
                )}
              </div>
            );
          })}
        </div>

        {/* <div
          style={{
            color: "#f3bd4e",
            fontSize: "3.5rem",
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px",
          }}
        >
          {" "}
          {value}
        </div> */}
        {/* {type ? <div style={{ fontSize: "2rem" }}>{type}</div> : null} */}
      </div>
    </div>
  );
}
