import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./pages/Login";
import DashboardPage from "./pages/Dashboard";
import { UserProvider } from "../src/context/UserProvider";
import { SnackProvider } from "../src/context/SnackProvider";
import ResetPassword from "./pages/ResetPassword";

function App(props) {
  return (
    <UserProvider>
      <SnackProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <LoginPage {...props} />
            </Route>
            <Route exact path="/dashboard">
              <DashboardPage {...props} show={"DASHBOARD"} />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword {...props}/>
            </Route>
            <Route exact path="/page">
              <DashboardPage {...props} show={"PAGE"} />
            </Route>
            {/* <Route exact path="/users">
              <DashboardPage {...props} show={"USERS"} />
            </Route> */}
            <Route exact path="/answers">
              <DashboardPage {...props} show={"ANSWERS"} />
            </Route>
          </Switch>
        </Router>
      </SnackProvider>
    </UserProvider>
  );
}

export default App;
