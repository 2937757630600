import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import api from "../services/api";
import { setToken } from "../services/cookies";
import { useUser } from "../context/UserProvider";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../assets/logo.png";
import { useLocation } from "react-router-dom";

function ResetPassword(props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(null);
  const [resetToken, setResetToken] = useState(undefined);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get(
      "resetPasswordToken"
    );
    if (token) {
      setResetToken(token);
    } else {
      history.push("/");
    }
  }, [location]);

  const handleReset = async () => {
    if (formData?.password.length && formData?.passwordConfirmation.length) {
      setError(false);
      setLoading(true);
      const res = await api.setNewPassword({
        code: resetToken,
        password: formData.password,
        passwordConfirmation: formData.passwordConfirmation,
      });
      console.log(res);
      if (res && res.ok) {
        setSuccess(true);
      } else {
        setError("Mots de passe invalides, veuillez réessayer.");
      }

      setLoading(false);
    }
  };
  return (
    <div id="auth-container">
      <div className={"card-wrap"}>
        {resetToken === undefined ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <>
            <img src={logo} alt={"Logo"} />
            {!success ? (
              <>
                <p>Définissez votre nouveau mot de passe.</p>
                <TextField
                  label="Mot de passe"
                  type={"password"}
                  className="text-field"
                  value={formData?.password || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  variant="outlined"
                />
                <TextField
                  label="Confirmer mot de passe"
                  type={"password"}
                  className="text-field"
                  value={formData?.passwordConfirmation || ""}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      passwordConfirmation: e.target.value,
                    })
                  }
                  variant="outlined"
                />
                {loading && (
                  <Alert
                    severity={"info"}
                    style={{ width: "calc(100% - 32px)", textAlign: "left" }}
                  >
                    Chargement...
                  </Alert>
                )}
                {error && (
                  <Alert
                    severity={"error"}
                    style={{ width: "calc(100% - 32px)", textAlign: "left" }}
                  >
                    {error}
                  </Alert>
                )}
                <div style={{ marginTop: "15px" }}>
                  <div
                    className={`button_main ${loading ? "is_loading" : ""}`}
                    onClick={handleReset}
                    style={{ pointerEvents: loading ? "none" : "all" }}
                  >
                    Réinitialiser
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2>Vous pouvez de nouveau vous connecter sur l'app.</h2>
                <div style={{ marginTop: "15px" }}>
                  <div
                    className={`button_main`}
                    onClick={() => history.push("/")}
                  >
                    Retour à la page de connexion
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default ResetPassword;
