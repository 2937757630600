import React, { useState } from "react";
import logo from "../assets/logo.png";
import { TextField } from "@material-ui/core";
import api from "../services/api";
import { setToken } from "../services/cookies";
import { useUser } from "../context/UserProvider";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Alert from '@material-ui/lab/Alert';


function LoginPage(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [formData, setFormData] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const { functions } = useUser();

  const handleLogin = async () => {
    if (formData?.password.length && formData?.email.length) {
      setError(false);
      setLoading(true);

      const res = await api.login({
        identifier: formData.email,
        password: formData.password,
      });

      setLoading(false);

      if (res) {
        // const jsonRes = await res.json();

        setToken(res.jwt);
        functions.setToken(res.jwt);
        // functions.setUser(getUser(res.jwt));

        // snack.functions.setSnack({
        //   message: content.SUCCESS.LOGIN,
        //   success: true,
        // });
        console.log("LOGGED");
        // setShowFullscreenLoader(true);
        history.push("/dashboard");
      } else {
        // setIsLoginIn(false);
        // setError(content.ERROR.LOGIN);
        console.log("FAILED TO LOGIN");

        setError("Impossible de vous connecter, veuillez vérifier votre email et mot de passe. Si l'erreur persiste, veuillez contacter notre équipe.")
      }
    } else {
      console.log("FILL IN ALL FIELDS");

    }

    // TODO:
    // get user
    // if user : is_company_admin OR is_city_admin
    // then you can get the city OR the company inside user.c..
  };

  return (
    <div id="auth-container">
      <div className={"card-wrap"}>
        <img src={logo} alt={"Logo"} />
        <p>Bienvenue sur la plateforme d'administration de Acctiv</p>
        <div className={"form-wrap"}>
          <TextField
            label="Email"
            type="email"
            className="text-field"
            value={formData?.email || ""}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            variant="outlined"
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Mot de passe"
              type={showPassword ? "text" : "password"}
              className="text-field"
              value={formData?.password || ""}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              variant="outlined"
            />
            <IconButton
              style={{
                fontSize: "1rem",
              }}
              aria-label="toggle password visibility"
              onClick={(e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              {!showPassword ? (
                <Visibility
                  style={{ width: "30px", height: "30px" }}
                  className="icons"
                />
              ) : (
                <VisibilityOff
                  className="icons"
                  style={{ width: "30px", height: "30px" }}
                />
              )}
            </IconButton>
          </div>
        </div>
        {loading && <Alert severity={"info"}>Connexion en cours...</Alert> }
        {error && <Alert severity={"error"}>{error}</Alert> }
        {/* <a href="#">J'ai oublié mon mot de passe</a> */}
        <div style={{ marginTop: "15px" }}>
          <div
            className={`button_main ${loading ? "is_loading" : ""}`}
            onClick={handleLogin}
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
