import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import api from "../services/api";
import moment from "moment";

export default function AnswersData({ users }) {
  const [allAnswers, setAllAnswers] = useState([]);
  const [dataCsv, setDataCsv] = useState(null);


  function csvObjectToString(obj) {
    if (!obj) return "";
    return `${obj.key}: ${obj.answer}`
  }

  useEffect(() => {
    if (allAnswers.length && users.length && !dataCsv) {
      const csv = [];
      const skip = ["address_home", "address_work"]

      /* Iterate in the list of user who answered to any survey */
      for (let user of users) {

        /* Get a list of user's answers */
        const userAnswers = allAnswers.filter(
          item => item.user.id === user.id
        );

        /* Sort by date of completion to have the oldest first */
        userAnswers.sort(function (a, b) {
          return new Date(a.created_at) - new Date(b.created_at);
        });

        /* Build the first set of column defining user data */
        let tmp =
        {
          user_id: user.id,
          cohort: moment().diff(moment(userAnswers?.[0]?.created_at), 'weeks'),
          profile: user.profile,
          motivations: user.motivations,
          motivation_intention: csvObjectToString(user.motivation_intention),
          created_at: new Date(user.created_at).toLocaleDateString("fr-FR"),
          transition_action_date:  new Date(user.transition_action_date).toLocaleDateString("fr-FR"),
          actual_goal_mode: user.goal?.[0]?.mode,
          actual_goal_date: new Date(user.goal?.[0]?.deadline).toLocaleDateString("fr-FR"),
          modes_tested: user.modes_tested,
          number_modes_tested: user.modes_tested?.length,
          phase: user.phase,
          phase_value: user.phase,
          city: user.city.name,
          company: user.company.name,
          number_of_answers: userAnswers.length,
        };

        /* Adding user's survey data answers */
        for (let item in user.survey_data) {
          if (skip.includes(item)) {
            continue;
          }
          tmp[`${item}`] = user.survey_data[item];
        }

        /* Variable to count the number of answers registered per survey
        that what we can have multiple columns for the same survey */
        let s1Answers = 0;
        let s2Answers = 0;
        let s3Answers = 0;

        let tmp1 = {}
        let tmp2 = {}
        let tmp3 = {}

        /* Iterate on the sorted list of user's answers and append the data to tmp dict */
        for (let answer of userAnswers) {

          /* Filter for onboarding survey */
          if (Object.keys(answer.data).includes("vp_everyday_solo")) {
            s1Answers++;
            
            if (s1Answers === 1) {
              tmp1[`created_at_1`] = new Date(answer.created_at).toLocaleDateString("fr-FR");
            }
            else {
              tmp1[`created_at_1_${s1Answers}`] =  new Date(answer.created_at).toLocaleDateString("fr-FR");
            }

            for (let item in answer.data) {
              if (skip.includes(item)) {
                continue;
              }
              if (s1Answers === 1) {
                tmp1[`${item}_1`] = answer.data[item].answer;
              }
              else {
                tmp1[`${item}_1_${s1Answers}`] = answer.data[item].answer;
              }
            }
          }
          /* Filter for second mandatory survey */
          else if (Object.keys(answer.data).includes('satisfaction_bilan')) {
            s2Answers++;

            tmp2[`created_at_2_${s2Answers}`] =  new Date(answer.created_at).toLocaleDateString("fr-FR");

            for (let item in answer.data) {
              if (skip.includes(item)) {
                continue;
              }
              tmp2[`${item}_2_${s2Answers}`] = answer.data[item].answer;
            }
          }
          /* Filter for other  survey */
          else {
            s3Answers++;

            tmp3[`created_at_S_${s3Answers}`] =  new Date(answer.created_at).toLocaleDateString("fr-FR");

            for (let item in answer.data) {
              if (skip.includes(item)) {
                continue;
              }
              tmp3[`${item}_S_${s3Answers}`] = String(answer.data[item].answer);
            }
          }
        }

        const csvRow = {
          ...tmp,
          ...tmp1,
          ...tmp2,
          ...tmp3
        }

        csv.push(csvRow);
      };

      setDataCsv([...csv]);
    }
  }, [allAnswers, users, dataCsv]);

  useEffect(() => {
    const init = async () => {
      const res = await api.get(`answers`);
      console.log(res)
      res && setAllAnswers(res);
    };
    init();
  }, []);
  return (
    <div className="users-table-wrap">
      <p>
        Les données ci-dessous reprennent toutes les réponses aux questionnaires
        des utilisateurs.

        L'ordre des colonnes est le suivant :
      </p>
      <ul>
        <li>informations anonymisées le splus à jour sur l'utilisateur
        </li>
        <li>
          puis réponses au questionnaire initial (suivi d'un _1)
        </li>
        <li>puis réponses au questionnaire 1 mois après l'inscription (suivi d'un _2) (peut être vide sir l'utilisateur utilise ACCTIV depuis moins de un mois)
        </li>
        <li>puis toutes les réponses au questionnaire simplifié (suivi d'un _3 puis du numéro de la reponse, exemple: _3_2 si l'utilisateur a répondu 2 fois au questionnaire simplifié)
        </li></ul>
      {/* <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="center">User id</TableCell>
              <TableCell align="center">User email</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Created at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allAnswers?.length &&
              allAnswers.map((item, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.user?.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.user?.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.type}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.created_at}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
  */}
      <div style={{ marginTop: "25px" }}>
        {dataCsv ? (
          <CSVLink
            data={dataCsv}
            className="button_main"
            filename={`answers-${new Date().toISOString()}.csv`}
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            target="_blank"
          >
            Télécharger en CSV <GetAppIcon className="icon" />
          </CSVLink>
        ) : (
          <div
            className="button_disabled"
            style={{ display: "flex", alignItems: "center" }}
          // onClick={() => handleDownloadCsv()}
          >
            csv <GetAppIcon className="icon" />
          </div>
        )}
      </div>
    </div>
  );
}
