import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { ClickAwayListener } from "@material-ui/core";
import "../../styles/modal.css";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";

export default function Modal({ closeModal, userInfoForModal }) {
  const [answers, setAnswers] = useState(null);
  const [surveys, setSurveys] = useState(null);
  const [dataCsv, setDataCsv] = useState(null);

  useEffect(() => {
    const fetchSurveys = async () => {
      const res = await api.get(`answers?user=${userInfoForModal.id}`);
      if (res) {
        setSurveys(res);
        setAnswers(res[0]);
      } else {
        console.log("Couldn't get answers");
      }
    };

    fetchSurveys();
  }, [userInfoForModal]);

  useEffect(() => {
    if (answers) {
      let formedData = [];

      userInfoForModal.mode &&
        formedData.push({
          number: "",
          question: "Mode",
          answer: userInfoForModal.mode,
        });

      userInfoForModal.phase &&
        formedData.push({
          number: "",
          question: "Phase",
          answer: userInfoForModal.phase,
        });

      Object.values(answers.data).map((item, key) => {
        return formedData.push({
          number: key + 1,
          question: item.question.text,
          answer:
            typeof item.answer === "string" ||
            typeof item.answer === "number" ||
            typeof item.answer === "boolean"
              ? item.answer
              : Array.isArray(item.answer) && item.answer.length
              ? item.answer[0]
              : !item.answer.length
              ? ""
              : item.name,
        });
      });

      setDataCsv(formedData);
    } else {
      setDataCsv(null);
    }
  }, [answers, userInfoForModal.mode, userInfoForModal.phase]);

  return (
    <div className="wrap">
      <ClickAwayListener onClickAway={closeModal}>
        <div className="modal-window ">
          <h2>Réponses au questionnaire</h2>
          {surveys ? (
            <div className="answers-wrap">
              <div className="surveys-line">
                {surveys.map((item, key) => {
                  return (
                    <div
                      key={key}
                      className={
                        item.id === answers?.id
                          ? "survey-item-picked"
                          : "survey-item"
                      }
                      onClick={() => setAnswers(item)}
                    >
                      {moment(item.created_at).format("L")}
                    </div>
                  );
                })}
              </div>
              <div className={answers ? "answers" : "no-answers"}>
                {answers &&
                  Object.values(answers.data).map((item, key) => {
                    return (
                      <div key={key} className="question-answer">
                        <span className="question">
                          {key + 1}. {item.question.text}
                        </span>
                        <span className="answer">
                          {typeof item.answer === "string" ||
                          typeof item.answer === "number" ||
                          typeof item.answer === "boolean"
                            ? `${item.answer}`
                            : Array.isArray(item.answer) && item.answer.length
                            ? item.answer[0]
                            : !item.answer.length
                            ? "-"
                            : item.name}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <h4>No surveys</h4>
          )}
          <div className="action-wrap">
            <div className="button_cancel" onClick={() => closeModal()}>
              Fermer
            </div>
            {dataCsv ? (
              <CSVLink
                data={dataCsv}
                className="button_main"
                filename={`answer-${new Date().toISOString()}.csv`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                target="_blank"
                // onClick={() => handleDownloadCsv()}
              >
                csv <GetAppIcon className="icon" />
              </CSVLink>
            ) : (
              <div
                className="button_disabled"
                style={{ display: "flex", alignItems: "center" }}
                // onClick={() => handleDownloadCsv()}
              >
                csv <GetAppIcon className="icon" />
              </div>
            )}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
}
