import { config } from "../config/default";
import { getToken } from "./cookies";

const api = {
  login: async (body) => {
    try {
      const res = await fetch(`${config.apiUrl}/auth/local`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  signup: async (body) => {
    try {
      const res = await fetch(`${config.apiUrl}/auth/local/register`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  setNewPassword: async (body) => {
    try {
      const res = await fetch(`${config.apiUrl}/auth/reset-password`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res;
    } catch (err) {
      console.error(err);
    }
  },
  get: async (resource, oneResourceId = false, query, isPublic = false) => {
    let headers = {
      "Content-Type": "application/json",
    };
    
    if (!isPublic) {
      headers["Authorization"] = "Bearer " + (await getToken());
    }

    try {
      const res = await fetch(
        `${config.apiUrl}/${resource}${
          oneResourceId ? "/" + oneResourceId : ""
        }${query ? query : ""}`,
        {
          method: "GET",
          headers: { ...headers },
        }
      );
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  create: async (resource, body, isPublic = false) => {
    let headers = {
      "Content-Type": "application/json",
    };
    // if (!isPublic) {
    //   headers["Authorization"] =
    //     "Bearer " + (await SecureStore.getItemAsync("userToken"));
    // }

    try {
      const res = await fetch(`${config.apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { ...headers },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  update: async (resource, oneResourceId, body) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getToken()),
    };

    try {
      const res = await fetch(
        `${config.apiUrl}/${resource}${
          oneResourceId ? "/" + oneResourceId : ""
        }`,
        {
          method: "PUT",
          body: JSON.stringify(body),
          headers: { ...headers },
        }
      );
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  uploadFile: async (body) => {
    try {
      const res = await fetch(`${config.apiUrl}/upload`, {
        method: "POST",
        body: body,
        headers: {
          Authorization: "Bearer " + (await getToken()),
        },
      });
      console.log(res);
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
};

export default api;
