import { getToken } from "./cookies";
import jwt from "jsonwebtoken";
// import USERTYPES from "../config/userTypes";

// export const formatSlug = (string) => {
//   return string.toLowerCase().replace(/\s/g, "-");
// };

// export const unformatSlug = (slug) => {
//   return slug
//     .replace(/-/g, " ")
//     .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
// };

// export const formatSlugTherapist = (therapist) => {
//   const name = `${therapist.firstname} ${therapist.lastname}`;
//   return `${name.toLowerCase().replace(/\s/g, "-")}-${therapist.id}`;
// };

// export const getIdFromSlug = (slug) => {
//   return slug.split("-").pop();
// };

export function getUser(token) {
  if (getToken() || token) {
    return jwt.decode(getToken() || token);
  } else {
    console.log("Could not get user");
    return null;
  }
}

// export function checkPassword(p) {
//   const regex = RegExp("^(?=.*[0-9])(?=.*[A-Za-z])(?=.{8,})(?=.*[!@#$%^&*])");
//   const test = regex.test(p);
//   return test;
// }

// export function checkPasswordMatch(p1, p2) {
//   return p1 === p2;
// }

// export function validateEmail(email) {
//   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// }

// export function needsStripeAccount(user) {
//   console.log(user);
//   if (!user) return false;
//   return user.user_type === USERTYPES.THERAPIST && !user.stripe_user_id;
// }

export function isTokenExpired(token) {
  if (jwt.decode(token).exp < Date.now() / 1000) {
    return true;
  } else {
    return false;
  }
}

// export function validatePhone(phone) {
//   let phoneFormat = /^\(?([0-9]{2})\)?[-. ]?([0-9]{2})?[-. ]?([0-9]{2})?[-. ]?([0-9]{2})?[-. ]?([0-9]{2})$/;
//   console.log(phoneFormat.test(phone));

//   if (phoneFormat.test(phone)) {
//     return true;
//   } else {
//     return false;
//   }
// }
