import React, { useReducer, useContext, useMemo } from "react";

const User = React.createContext();

const initiState = {
  user: null,
  token: null,
  need_stripe: false,
  stripe_data: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return Object.assign({}, state, {
        ...state,
        user: action.user,
      });
    case "SET_TOKEN":
      return Object.assign({}, state, { ...state, token: action.token });
    case "SET_NEED_STRIPE":
      return Object.assign({}, state, {
        ...state,
        need_stripe: action.need_stripe,
      });
    case "SET_STRIPE_DATA":
      return Object.assign({}, state, {
        ...state,
        stripe_data: { stripe_id: action.stripe_id, user_id: action.user_id },
      });
    case "CLEAR_STRIPE_DATA":
      return Object.assign({}, state, {
        ...state,
        stripe_data: null,
      });
    case "LOG_OUT":
      return Object.assign({}, state, {
        ...state,
        user: null,
        token: null,
        need_stripe: false,
      });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initiState);

  const functions = useMemo(
    () => ({
      setUser: async (data) => dispatch({ type: "SET_USER", user: data }),
      setToken: async (data) => dispatch({ type: "SET_TOKEN", token: data }),
      setNeedStripe: async (data) =>
        dispatch({ type: "SET_NEED_STRIPE", need_stripe: data }),
      setStripeData: async (data) =>
        dispatch({ type: "SET_STRIPE_DATA", stripe_data: data }),
      logOut: async () => dispatch({ type: "LOG_OUT" }),
      clearStripe: async () => dispatch({ type: "CLEAR_STRIPE_DATA" }),
    }),
    []
  );

  return (
    <User.Provider
      value={{
        need_stripe: state.need_stripe,
        user: state.user,
        token: state.token,
        stripe_data: state.stripe_data,
        functions,
      }}
    >
      {children}
    </User.Provider>
  );
};

export const useUser = () => useContext(User);
