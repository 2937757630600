import Cookies from "js-cookie";

export function setToken(token) {
  console.log(token);
  Cookies.set("token", token);
}

export function getToken() {
  return Cookies.get("token");
}

export function removeToken() {
  console.log("removing token");
  Cookies.set("token", "");
}

// export function setConfig(config, mode) {
//   Cookies.set("configApi", config[mode].apiUrl);
//   console.log("API is running on " + Cookies.get("configApi"));
//   Cookies.set("configSocketIo", config[mode].socketIoUrl);
//   console.log("Socket Io is running on " + Cookies.get("configSocketIo"));
//   Cookies.set("configMode", mode);
//   console.log("Setting config to mode : " + mode);
// }

// export function getConfigSocket() {
//   return Cookies.get("configSocketIo");
// }

// export function setUserUID(UID) {
//   Cookies.set("UID", UID);
// }

// export function getUserInfo() {
//   const name = Cookies.get("name");
//   const UID = Cookies.get("UID");
//   const info = {
//     name,
//     UID,
//   };
//   return info;
// }

// export function setUserInfo(info) {
//   Cookies.set("name", info.name);
// }
