import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import logo from "../assets/logowhite.png";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SurveyIcon from "@material-ui/icons/QuestionAnswer";
import { useLocation, useHistory } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import { removeToken } from "../services/cookies";
import TocIcon from "@material-ui/icons/Toc";

function Sidebar({ openDrawer }) {
  const history = useHistory();
  const location = useLocation();
  const { functions } = useUser();

  const handleLogout = () => {
    removeToken();
    functions.logOut();
    if (location.pathname !== "/") {
      history.push("/");
    }
  };

  return (
    <>
      <img
        src={logo}
        style={{ objectFit: "contain", maxHeight: 80, margin: "2rem 1rem" }}
        alt={"Logo"}
      />
      <Divider />
      <List>
        <ListItem
          button
          style={
            location.pathname === "/dashboard"
              ? { backgroundColor: "#f3bd4e" }
              : null
          }
          onClick={() => history.push("/dashboard")}
        >
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem
          button
          style={
            location.pathname === "/answers"
              ? { backgroundColor: "#f3bd4e" }
              : null
          }
          onClick={() => history.push("/answers")}
        >
          <ListItemIcon>
            <SurveyIcon />
          </ListItemIcon>
          <ListItemText primary={"Réponses aux questionnaires"} />
        </ListItem>
        <ListItem
          button
          style={
            location.pathname === "/page"
              ? { backgroundColor: "#f3bd4e" }
              : null
          }
          onClick={() => history.push("/page")}
        >
          <ListItemIcon>
            <TocIcon />
          </ListItemIcon>
          <ListItemText primary={"Gérer ma page"} />
        </ListItem>
      </List>
      <Divider />
      <ListItem
        button
        style={{
          marginTop: "auto",
          paddingBottom: "1rem",
          paddingTop: "1rem",
        }}
      >
        <ListItemAvatar>
          <Avatar src={null} alt="A" />
        </ListItemAvatar>
        <ListItemText primary={"Mon compte"} />
      </ListItem>
      <ListItem>
        <div className="button_secondary" onClick={handleLogout}>
          Se déconnecter
        </div>
      </ListItem>
    </>
  );
}

export default Sidebar;
